//
// variables.scss
//

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

// Color
$white:                     #ffffff;

$primary:                   #2a9df4; //2a9df4
$secondary:                 #5a6d90;
$success:                   #2eca8b;
$warning:                   #f17425;
$info:                      #17a2b8;
$danger:                    #e43f52;
$dark:                      #3c4858;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f8f9fc;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;

// Overlay
$overlay:                   rgba($dark, 0.7);
$gradident-overlay:         rgba($primary, 0.6);
$bg-overlay-white:          rgba($white, 0.5);

//Body Background
$shadow:                    0 0 3px rgba($dark,.15);
$shadow-md:                 0 5px 13px rgba($dark, .2);
$shadow-lg:                 0 10px 25px rgba($dark, 0.15);
$footer:                    #08112b;

// Base font
$font-size-base:            16px;

$font-family-base:          'Nunito', sans-serif;
$font-family-secondary:     'Nunito', sans-serif;
$link-font: 'Rubik', sans-serif;
//Color Picker/Switcher
$default:                   #2a9df4;
$green:                     #6dc77a;
$red:                       #ff5b69;
$purple:                    #7952B3;
$skyblue:                   #32c2ec;
$skobleoff:                 #0f7173;
$cyan:                      #00c9a7;
$slateblue:                 #766df4;
$yellow:                    #f7961c;

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "blue": $primary,
    "footer": $footer
);

$heading-font-sizes: (
    "h1": 42px,
    "h2": 36px,
    "h3": 30px,
    "h4": 24px,
    "h5": 20px,
    "h6": 16px,
);

// icon-box area-variables
$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";

$heading-color: #030f27;


// contact variables
$heading-font: "Nunito", sans-serif;
$yellow-color-2: #F8A440;
$bodyFont: 'Poppins', sans-serif;
$bodyColor: #333333;
$theme-color:white;
$heading-color: #222222;

// Layouts
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$sm-laptop-device: 'only screen and (min-width: 1300px) and (max-width: 1599px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$custom-lg-layout: 'only screen and (min-width: 1021px) and (max-width: 1090px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

$display-font-sizes: (
    "display-1": 80px,
    "display-2": 72px,
    "display-3": 64px,
    "display-4": 56px,
)
